import { Card, Icon, Page, Spinner, Tabs } from "@shopify/polaris"
import { useEffect, useState } from "react"
import Sections from "./Sections"
import Templates from "./Templates"
import Themes from "./Themes"
import Styles from "./Styles"
import TemplateBuilder from "./TemplateBuilder"
import ThemeBuilder from "./ThemeBuilder"
import Filter from "./Filter"
import { XIcon } from '@shopify/polaris-icons';
import { format } from "date-fns"
import TopTheme from "./TopTheme"


function TopInstalled({ username }) {
    const [selected, setSelected] = useState(0);
    const [filters, setFilters] = useState({
        shopifyPlan: [],
        appPlan: [],
        pageType: [],
        itemPackage: []
    });
    const [loading, setLoading] = useState(false);

    const tabs = [
        {
            id: 'top-sections',
            content: 'Section',
            panelID: 'top-sections',
        },
        {
            id: 'top-templates',
            content: 'Template',
            panelID: 'top-templates',
        },
        {
            id: 'top-themes',
            content: 'Theme',
            panelID: 'top-themes',
        },
        {
            id: 'top-styles',
            content: 'Style',
            panelID: 'top-styles',
        },
        {
            id: 'top-template-builders',
            content: 'Page builder',
            panelID: 'top-template-builders',
        },
        {
            id: 'top-theme-builders',
            content: 'Theme builder',
            panelID: 'top-theme-builders',
        },
        {
            id: 'top-theme-installer',
            content: 'Top theme',
            panelID: 'top-theme-installer',
        }
    ];

    const handleTabChange = (selectedTabIndex) => {
        setSelected(selectedTabIndex)
    };

    const handleChangeFilter = (value) => {
        const _filters = {
            ...value
        };

        const alias = _filters.dateRange.alias;
        let interval = 'hour';
        let startDate = _filters.dateRange.period.since;
        let endDate = _filters.dateRange.period.until;

        switch (alias) {
            case 'custom':
                interval = 'day';
                startDate = new Date(startDate.setHours(0, 0, 0, 0));
                endDate = new Date(endDate.setHours(23, 59, 59, 999));
                break;
            case 'last7days':
            case 'last30days':
            case 'lastmonth':
                interval = 'day';
                break;
            case 'last90days':
                interval = 'week';
                break;
            case 'last365days':
            case 'lastyear':
                interval = 'month';
                break;
        }

        _filters.dateRangeFilter = {
            endDate: new Date(endDate).toISOString(),
            interval,
            startDate: new Date(startDate).toISOString()
        }

        setFilters(_filters);
    }

    useEffect(() => {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const endToday = new Date(new Date().setHours(23, 59, 59, 999));

        const defaultDateRange = {
            title: "Today",
            alias: "today",
            period: {
                since: today,
                until: endToday,
            },
        }

        const _filters = { ...filters };
        _filters.dateRange = defaultDateRange;

        handleChangeFilter(_filters);
    }, [])

    return (
        <div className="admin-merchant-logs-page p-relative">
            <Page title="Top installed" >
                <Card padding={200}>
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <div className="p-300">
                            <div className="d-flex align-items-center gap-200">
                                <Filter onChange={handleChangeFilter} data={filters} showPageType={selected === 1 || selected === 4 ? true : false} showPackage={selected === 0 || selected === 1 ? true : false} />
                                {!!loading ? <Spinner size="small" /> : null}
                            </div>
                            {
                                filters?.shopifyPlan.length ?
                                    (
                                        <div className="list-tags d-flex gap-200">
                                            Shopify plan
                                            {
                                                filters?.shopifyPlan.map(item => {
                                                    return (
                                                        <span key={item} className="tag d-flex">{item} <span onClick={() => {
                                                            const remainShopifyPlan = filters.shopifyPlan.filter(plan => plan !== item);
                                                            setFilters(prev => ({
                                                                ...prev,
                                                                shopifyPlan: remainShopifyPlan
                                                            }))
                                                        }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null
                            }

                            {
                                filters?.appPlan.length ?
                                    (
                                        <div className="list-tags d-flex gap-200">
                                            App plan
                                            {
                                                filters?.appPlan.map(item => {
                                                    return (
                                                        <span key={item} className="tag d-flex">{item} <span onClick={() => {
                                                            const remainAppPlan = filters.appPlan.filter(plan => plan !== item);
                                                            setFilters(prev => ({
                                                                ...prev,
                                                                appPlan: remainAppPlan
                                                            }))
                                                        }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null
                            }

                            {
                                filters?.itemPackage.length && (selected === 0 || selected === 1) ?
                                    (
                                        <div className="list-tags d-flex gap-200">
                                            Package
                                            {
                                                filters?.itemPackage.map(item => {
                                                    return (
                                                        <span key={item} className="tag d-flex">{item.toUpperCase()} <span onClick={() => {
                                                            const remainItemPackage = filters.itemPackage.filter(plan => plan !== item);
                                                            setFilters(prev => ({
                                                                ...prev,
                                                                itemPackage: remainItemPackage
                                                            }))
                                                        }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null
                            }

                            {
                                filters?.pageType.length && (selected === 1 || selected === 4) ?
                                    (
                                        <div className="list-tags d-flex gap-200">
                                            Page type
                                            {
                                                filters?.pageType.map(item => {
                                                    return (
                                                        <span key={item} className="tag d-flex">{item} <span onClick={() => {
                                                            const remainPageType = filters.pageType.filter(plan => plan !== item);
                                                            setFilters(prev => ({
                                                                ...prev,
                                                                pageType: remainPageType
                                                            }))
                                                        }}><Icon source={XIcon} tone="base"></Icon></span></span>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : null
                            }
                        </div>
                        <div className="p-300">
                            {selected === 0 ? (<Sections filters={filters} username={username} />) : null}
                            {selected === 1 ? (<Templates filters={filters} username={username} />) : null}
                            {selected === 2 ? (<Themes filters={filters} username={username} />) : null}
                            {selected === 3 ? (<Styles filters={filters} username={username} />) : null}
                            {selected === 4 ? (<TemplateBuilder filters={filters} />) : null}
                            {selected === 5 ? (<ThemeBuilder filters={filters} />) : null}
                            {selected === 6 ? (<TopTheme filters={filters} username={username} />) : null}
                        </div>
                    </Tabs>
                </Card>
            </Page>
        </div>
    )
}

export default TopInstalled