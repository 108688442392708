import { Button, Icon, Select, Text, TextField } from "@shopify/polaris"
import { useEffect, useState } from "react";
import { ENDPOINT } from "../../utils/functions";
import { ViewIcon } from '@shopify/polaris-icons';


function Sections() {
    const [cores, setCores] = useState([])
    const [loading, setLoading] = useState(false)
    const [packages, setPackages] = useState({})

    useEffect(() => {
        const getCores = async (query) => {
            try {
                const response = await fetch(`${ENDPOINT}/api/get-cores`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const result = await response.json();
                if (result.status) {
                    setCores(result.data)
                    const _packages = {}
                    result.data.map(item => {
                        _packages[item._id.core] = item._id.package
                    })
                    setPackages(_packages)
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }

        getCores()
    }, [])

    const handleSave = async () => {
        setLoading(true)
        const response = await fetch(`${ENDPOINT}/api/set-packages`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                packages
            }),
        });

        setLoading(false)

        if (response.status) {
            alert('Package updated')
        } else {
            alert('Updating fail')
        }
    }

    return (
        <div className="all-merchants">
            <table className="mt-300" style={{ width: '400px', marginBottom: '20px' }}>
                <thead>
                    <tr>
			<th>&nbsp;</th>
                        <th>Core</th>
                        <th>Package</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cores.map((core, index) => {
                            return (
                                <tr key={`sfsdf-${index}`}>
					<td>
<Text as="h3" variant="bodyLg" fontWeight="medium">{(index + 1)}</Text>
</td>
                                    <td>
                                        <div className="d-flex gap-400">
                                            <Text as="h3" variant="bodyLg" fontWeight="medium">
                                                <span style={{ 'text-transform': 'capitalize' }} key={index}>{core._id.core.replaceAll('-', ' ')}</span>
                                            </Text>
                                            <a href={core.preview} target="_blank">
                                                <Icon source={ViewIcon} tone="base" />
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <Select
                                            options={[
                                                { label: 'Free', value: 'free' },
                                                { label: 'Basic', value: 'basic' },
                                                { label: 'Pro', value: 'pro' }
                                            ]}
                                            value={packages?.[core._id.core]}
                                            onChange={(value) => {
                                                setPackages(prev => ({ ...prev, [core._id.core]: value }))
                                            }}
                                        >
                                        </Select>
                                    </td>
                                </tr>

                            )
                        })
                    }
                </tbody>
            </table>
            <div className="mt-400 pricing-section-button" >
                <Button variant="primary" fullWidth size="large" loading={loading} onClick={handleSave}>Update</Button>
            </div>
        </div>
    )
}

export default Sections