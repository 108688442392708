import { Button, ChoiceList, Popover, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";
import DateRangePicker from "./DateRangePicker";

function Filter({ data, showPageType, showPackage, onChange }) {
    const [popoverShopifyPlanActive, setPopoverShopifyPlanActive] = useState(false);
    const [shopifyPlan, setShopifyPlan] = useState([]);

    const [popoverAppPlanActive, setPopoverAppPlanActive] = useState(false);
    const [appPlan, setAppPlan] = useState([]);

    const [popoverPageTypeActive, setPopoverPageTypeActive] = useState(false);
    const [pageType, setPageType] = useState([]);

    const [popoverItemPackageActive, setPopoverItemPackageActive] = useState(false);
    const [itemPackage, setItemPackage] = useState([]);

    const [dateRange, setDateRange] = useState({});

    const togglePopoverShopifyPlanActive = () => {
        setPopoverShopifyPlanActive((popoverActive) => !popoverActive)
    };
    const handleChangeShopifyPlan = (value) => {
        setShopifyPlan(value);
        onChange({
            shopifyPlan: value,
            appPlan: appPlan,
            pageType: pageType,
            dateRange: dateRange,
            itemPackage: itemPackage
        })
    };

    const togglePopoverAppPlanActive = () => {
        setPopoverAppPlanActive((popoverActive) => !popoverActive)
    };
    const handleChangeAppPlan = (value) => {
        setAppPlan(value);
        onChange({
            shopifyPlan: shopifyPlan,
            appPlan: value,
            pageType: pageType,
            dateRange: dateRange,
            itemPackage: itemPackage
        })
    };

    const togglePopoverPageTypeActive = () => {
        setPopoverPageTypeActive((popoverActive) => !popoverActive)
    };
    const handleChangePageType = (value) => {
        setPageType(value);
        onChange({
            shopifyPlan: shopifyPlan,
            appPlan: appPlan,
            pageType: value,
            dateRange: dateRange,
            itemPackage: itemPackage
        })
    };

    const togglePopoverItemPackageActive = () => {
        setPopoverItemPackageActive((popoverActive) => !popoverActive)
    };
    const handleChangeItemPackage = (value) => {
        setItemPackage(value);
        onChange({
            itemPackage: value,
            shopifyPlan: shopifyPlan,
            appPlan: appPlan,
            pageType: pageType,
            dateRange: dateRange
        })
    };

    const handleDateChange = (value) => {
        setDateRange(value);
        onChange({
            dateRange: value,
            shopifyPlan: shopifyPlan,
            appPlan: appPlan,
            pageType: pageType,
            itemPackage: itemPackage,
        })
    }

    useEffect(() => {
        const _shopifyPlans = data?.shopifyPlan;
        setShopifyPlan(_shopifyPlans);

        const _appPlans = data?.appPlan;
        setAppPlan(_appPlans);

        const _pageType = data?.pageType;
        setPageType(_pageType);

        const _itemPackage = data?.itemPackage;
        setItemPackage(_itemPackage);

        const _dateRange = data?.dateRange;
        setDateRange(_dateRange);
    }, [data]);

    return (
        <>
            <DateRangePicker onChange={handleDateChange} data={dateRange} />
            <Popover
                active={popoverShopifyPlanActive}
                activator={(
                    <Button onClick={togglePopoverShopifyPlanActive}>Shopify plan</Button>
                )}
                onClose={togglePopoverShopifyPlanActive}
                ariaHaspopup={false}
                sectioned
                preferredAlignment="left"
            >
                <ChoiceList
                    allowMultiple
                    choices={[
                        {
                            label: 'Development',
                            value: 'Development'
                        },
                        {
                            label: 'Basic',
                            value: 'Basic'
                        },
                        {
                            label: 'Advanced',
                            value: 'Advanced'
                        },
                        {
                            label: 'Shopify Plus',
                            value: 'Shopify Plus'
                        },
                        {
                            label: 'Trial',
                            value: 'Trial'
                        },
                        {
                            label: 'Shopify',
                            value: 'Shopify'
                        },
                        {
                            label: 'Pause and Build',
                            value: 'Pause and Build'
                        },
                    ]}
                    selected={shopifyPlan}
                    onChange={handleChangeShopifyPlan}
                />
                <div className="" style={{ marginTop: '5px' }}>
                    <Button variant="plain" onClick={() => {
                        handleChangeShopifyPlan([
                            'Development',
                            'Basic',
                            'Advanced',
                            'Shopify Plus',
                            'Trial',
                            'Shopify',
                            'Pause and Build'
                        ])
                    }}>Select all</Button>
                </div>
                <div className="" style={{ marginTop: '5px' }}>
                    <Button variant="plain" onClick={() => {
                        handleChangeShopifyPlan([
                            'Basic',
                            'Advanced',
                            'Shopify Plus',
                            'Trial',
                            'Shopify',
                            'Pause and Build'
                        ])
                    }}>Select w/o dev plan</Button>
                </div>
                {
                    shopifyPlan.length > 0 ? <div className="" style={{ marginTop: '5px' }}>
                        <Button tone="critical" variant="plain" onClick={() => {
                            handleChangeShopifyPlan([])
                        }}>Clear all</Button>
                    </div> : null
                }
            </Popover>

            <Popover
                active={popoverAppPlanActive}
                activator={(
                    <Button onClick={togglePopoverAppPlanActive}>App plan</Button>
                )}
                onClose={togglePopoverAppPlanActive}
                ariaHaspopup={false}
                sectioned
                preferredAlignment="left"
            >
                <ChoiceList
                    allowMultiple
                    title="App plan"
                    choices={[
                        {
                            label: '[MONTHLY] Free',
                            value: '[MONTHLY] Free'
                        },
                        {
                            label: '[MONTHLY] Basic',
                            value: '[MONTHLY] Basic'
                        },
                        {
                            label: '[ANNUALLY] Basic',
                            value: '[ANNUALLY] Basic'
                        },
                        {
                            label: '[MONTHLY] Pro',
                            value: '[MONTHLY] Pro'
                        },
                        {
                            label: '[ANNUALLY] Pro',
                            value: '[ANNUALLY] Pro'
                        },
                        {
                            label: '[MONTHLY] Premium',
                            value: '[MONTHLY] Premium'
                        },
                        {
                            label: '[ANNUALLY] Premium',
                            value: '[ANNUALLY] Premium'
                        }
                    ]}
                    selected={appPlan}
                    onChange={handleChangeAppPlan}
                />
                <div className="" style={{ marginTop: '5px' }}>
                    <Button variant="plain" onClick={() => {
                        handleChangeAppPlan([
                            '[MONTHLY] Free',
                            '[MONTHLY] Basic',
                            '[ANNUALLY] Basic',
                            '[MONTHLY] Pro',
                            '[ANNUALLY] Pro',
                            '[MONTHLY] Premium',
                            '[ANNUALLY] Premium'
                        ])
                    }}>Select all</Button>
                </div>
                {
                    appPlan.length > 0 ? <div className="" style={{ marginTop: '5px' }}>
                        <Button tone="critical" variant="plain" onClick={() => {
                            handleChangeAppPlan([])
                        }}>Clear all</Button>
                    </div> : null
                }
            </Popover>

            {
                !!showPackage ? (
                    <Popover
                        active={popoverItemPackageActive}
                        activator={(
                            <Button onClick={togglePopoverItemPackageActive}>Package</Button>
                        )}
                        onClose={togglePopoverItemPackageActive}
                        ariaHaspopup={false}
                        sectioned
                        preferredAlignment="left"
                    >
                        <ChoiceList
                            allowMultiple
                            title="Package"
                            choices={[
                                {
                                    label: 'Free',
                                    value: 'free'
                                },
                                {
                                    label: 'Basic',
                                    value: 'basic'
                                },
                                {
                                    label: 'Pro',
                                    value: 'pro'
                                }
                            ]}
                            selected={itemPackage}
                            onChange={handleChangeItemPackage}
                        />
                        <div className="" style={{ marginTop: '5px' }}>
                            <Button variant="plain" onClick={() => {
                                handleChangeItemPackage([
                                    'free',
                                    'basic',
                                    'pro'
                                ])
                            }}>Select all</Button>
                        </div>
                        {
                            itemPackage.length > 0 ? <div className="" style={{ marginTop: '5px' }}>
                                <Button tone="critical" variant="plain" onClick={() => {
                                    handleChangeItemPackage([])
                                }}>Clear all</Button>
                            </div> : null
                        }
                    </Popover>
                ) : null
            }

            {
                showPageType ? (
                    <Popover
                        active={popoverPageTypeActive}
                        activator={(
                            <Button onClick={togglePopoverPageTypeActive}>Page type</Button>
                        )}
                        onClose={togglePopoverPageTypeActive}
                        ariaHaspopup={false}
                        sectioned
                        preferredAlignment="left"
                    >
                        <ChoiceList
                            allowMultiple
                            title="Page type"
                            choices={[
                                {
                                    label: 'Home',
                                    value: 'index'
                                },
                                {
                                    label: 'Product',
                                    value: 'product'
                                },
                                {
                                    label: 'Collection',
                                    value: 'collection'
                                },
                                {
                                    label: 'Page',
                                    value: 'page'
                                },
                                {
                                    label: 'Blog',
                                    value: 'blog'
                                },
                                {
                                    label: 'Blog post',
                                    value: 'article'
                                }
                            ]}
                            selected={pageType}
                            onChange={handleChangePageType}
                        />
                        <div className="" style={{ marginTop: '5px' }}>
                            <Button variant="plain" onClick={() => {
                                handleChangePageType([
                                    'index',
                                    'product',
                                    'collection',
                                    'page',
                                    'blog',
                                    'article'
                                ])
                            }}>Select all</Button>
                        </div>
                        {
                            pageType.length > 0 ? <div className="" style={{ marginTop: '5px' }}>
                                <Button tone="critical" variant="plain" onClick={() => {
                                    handleChangePageType([])
                                }}>Clear all</Button>
                            </div> : null
                        }
                    </Popover>
                ) : null
            }
        </>
    )
}

export default Filter;