import { Pagination as PolarisPagination } from "@shopify/polaris"
import { ceil } from "lodash"

function Pagination({ total, limit, page, onChange }) {
    if (ceil(total / limit) <= 1) {
        return <></>
    }
    return (
        <div className="mt-400 d-flex justify-center">
            <PolarisPagination
                label={`${page}/${ceil(total / limit)}`}
                hasPrevious={page === 1 ? false : true}
                onPrevious={() => {
                    onChange(page - 1)
                }}
                hasNext={page === ceil(total / limit) ? false : true}
                onNext={() => {
                    onChange(page + 1)
                }}
            />
        </div>
    )
}

export default Pagination