import { Pagination as PolarisPagination, Spinner } from '@shopify/polaris';
function Pagination({ data, loading, onChange }) {
    const { currentPage, totalPages } = data;
    
    return (
        <>
            {
                totalPages > 1 ? <div className='d-flex justify-center p-600'>
                    <PolarisPagination
                        hasPrevious={currentPage > 1}
                        onPrevious={() => onChange((currentPage - 1))}
                        hasNext={currentPage < totalPages}
                        onNext={() => onChange((currentPage + 1))}
                        label={!!loading ? <Spinner size='small' /> : `${currentPage}/${totalPages}`}
                    />
                </div> : null
            }
        </>
    )
}

export default Pagination;