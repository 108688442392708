const ENDPOINT = 'https://tools-api.smind.io'
//const ENDPOINT = 'http://localhost:3008'

const convertToAsiaTimeZone = (_date) => {
    const date = new Date(_date);

    // Convert to local time with UTC+7 timezone offset
    const localDateString = date.toLocaleString('vi-VN', {
        timeZone: 'Asia/Bangkok', // UTC+7 timezone
        hour12: false, // Use 24-hour format
    });

    const temp = localDateString.split(' ')
    return `${temp[1]} ${temp[0]}`
}

export {
    ENDPOINT,
    convertToAsiaTimeZone
}