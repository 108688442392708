import { Badge, Banner, Button, DataTable, Modal, Spinner, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { ENDPOINT } from "../../utils/functions";
import { format } from "date-fns";
import Pagination from "./Pagination";

function TemplateBuilder({ filters }) {
    const [rows, setRows] = useState([[<Banner>No data</Banner>]]);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [modalActive, setModalActive] = useState(false);
    const [detailRows, setDetailRows] = useState([]);
    const [modalTitle, setModalTitle] = useState('Detail');
    const [handles, setHandles] = useState({});
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1
    });
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
        const handleFilter = async () => {
            if (filters?.dateRangeFilter) {
                const filterObj = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...filters,
                        dataType: 'template_builder'
                    }),
                };

                const responseCounter = await fetch(`${ENDPOINT}/api/get-counter-installations`, filterObj);
                const resultCounter = await responseCounter.json();
                setCounter(resultCounter.data);
            }
        }

        handleFilter();
    }, [filters])

    useEffect(() => {
        const handleFilter = async () => {
            setLoading(true);
            setRows([[
                <div className="d-flex align-items-center justify-center p-600"><Spinner /></div>
            ]]);

            const response = await fetch(`${ENDPOINT}/api/get-top-installations`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...filters,
                    dataType: 'template_builder',
                    page
                }),
            });
            const result = await response.json();
            if (result.data.length) {
                const _rows = [];

                const startIndex = (page - 1) * 100;
                result.data.map((item, index) => {
                    _rows.push([
                        <b>{(startIndex + index + 1)}</b>,
                        <b>{item?.name ?? 'Blank template'}</b>,
                        <b>{item.count}</b>,
                        <>
                            <Button onClick={async () => { handleViewDetail(item, filters); }}>Detail</Button>
                        </>,
                    ]);
                })
                setRows(_rows);

            } else {
                setRows([[
                    <Banner>No data</Banner>
                ]]);
            }
            setPagination(result.pagination);
            setLoading(false);
        }

        handleFilter();

    }, [filters, page])

    const handleViewDetail = async (item, filters) => {
        setModalTitle(`Detail for "${item?.name ?? 'Blank template'}"`);
        setModalActive(true);
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/get-detail-installations`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...filters,
                dataType: 'template_builder',
                handle: item.handle
            }),
        });
        const result = await response.json();

        const _rows = [];
        let shopUrls = [];
        result.data.map((itemData) => {
            _rows.push({
                ...itemData.items.meta,
                shopifyPlan: itemData.items.shopifyPlan,
                appPlan: itemData.items.appPlan,
                createdAt: format(itemData.items.createdAt, "dd-MM-yyyy HH:mm:ss", { timeZone: 'Asia/Bangkok' }),
                pageType: itemData.items?.pageType
            });
            if (itemData.items.meta?.shopUrl) {
                shopUrls.push(itemData.items.meta?.shopUrl);
            }
        });
        setDetailRows(_rows);

        shopUrls = [...new Set(shopUrls)];
        const responseHandles = await fetch(`${ENDPOINT}/api/get-handles`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrls
            }),
        });
        const resultHandles = await responseHandles.json();
        setHandles(resultHandles.data);
        setLoading(false);
    }

    return (
        <div>
            <div style={{ width: '700px' }}>
                <Banner hideIcon><Text variant="headingLg">{counter} {counter === 1 ? 'template' : 'templates'} built</Text></Banner>
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text'
                    ]}
                    headings={[
                        <b>#</b>,
                        <b>Name</b>,
                        <b>Number of installations ({counter})</b>,
                        <b></b>
                    ]}
                    rows={rows}
                />
                <Pagination data={pagination} loading={loading} onChange={value => setPage(value)} />
            </div>

            <Modal
                size="large"
                open={modalActive}
                onClose={() => {
                    setModalActive(false);
                }}
                title={modalTitle}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: () => {
                            setModalActive(false);
                        },
                    },
                ]}
            >
                <Modal.Section>
                    {
                        !!loading ? (
                            <div className="d-flex align-items-center justify-center" style={{ minHeight: '200px' }}>
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                <table className="detail-table">
                                    <tr>
                                        <th>Shop URL</th>
                                        <th>Page/Template</th>
                                        <th>App plan</th>
                                        <th>Shopify plan</th>
                                        <th>Created at</th>
                                    </tr>
                                    {
                                        detailRows.map((item) => {
                                            const handleObj = handles?.[item?.shopUrl];
                                            const shop = item?.shopUrl;
                                            const type = item?.pageType;
                                            let url = '';

                                            switch (type) {
                                                case 'blog':
                                                    url = `https://${shop}/blogs/${handleObj?.blog}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    break;
                                                case 'article':
                                                    url = `https://${shop}/blogs/${handleObj?.blog}/${handleObj?.article}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    break;
                                                case 'product':
                                                    url = `https://${shop}/products/${handleObj?.product}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    break;
                                                case 'collection':
                                                    url = `https://${shop}/collections/${handleObj?.collection}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    break;
                                                case 'page':
                                                    if (!!item.createdPage) {
                                                        url = `https://${shop}/pages/${item.pageHandle}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    } else {
                                                        url = `https://${shop}/pages/${handleObj?.page}?view=smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    }
                                                    break;
                                                case 'index':
                                                    url = `https://${shop}/pages/${handleObj?.page}?view=index.smi-${item.templateHandle}&preview_theme_id=${item.themeId}`;
                                                    break;
                                                default:
                                                    url = `https://${shop}?preview_theme_id=${item.themeId}`;
                                                    break;
                                            }

                                            return (
                                                <tr>
                                                    <td>
                                                        <a target="_blank" href={url}>{item?.shopUrl}</a>
                                                    </td>
                                                    <td>
                                                        {
                                                            !!item.createdPage ?
                                                                <><a target="_blank" href={url}>{item?.pageName ?? '--'}</a> <Badge tone="success">Page</Badge></> :
                                                                <><a target="_blank" href={url}>{item?.templateName ?? '--'}</a> <Badge tone="attention">Template</Badge></>
                                                        }
                                                    </td>
                                                    <td>{item?.appPlan ?? '--'}</td>
                                                    <td>{item?.shopifyPlan ?? '--'}</td>
                                                    <td>{item?.createdAt ?? '--'}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        )
                    }
                </Modal.Section>
            </Modal>
        </div>
    )
}

export default TemplateBuilder;