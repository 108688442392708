import { ActionList, Icon, Popover, TextField } from "@shopify/polaris";
import { SearchIcon } from '@shopify/polaris-icons';
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ENDPOINT } from "../../../utils/functions";

function SearchThemes({currentItems, onSelectItem}) {      
    const [popoverActive, setPopoverActive] = useState(true);
    const [searchText, setSearchText] = useState('')
    const [searchLoading, setSearchLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(currentItems);        
    }, [currentItems]);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const [sections, setSections] = useState([]);

    const delayedSearch = useCallback(
        debounce(async(query) => {
            setSearchLoading(true);
            const handles = items.map(item => item.handle);                
            
            const response = await fetch(`${ENDPOINT}/api/search-themes`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    handles,
                    _s: query
                }),
            });  
            const result = await response.json();    
            let _sections = [];
            result.data.map(item => {
                _sections.push({
                    content: item.name,
                    onAction: () => {
                        setPopoverActive(false);
                        setSearchText('')
                        onSelectItem(item);
                    },
                  });
            })
            setSections(_sections);
            setPopoverActive(true);
            setSearchLoading(false);
        }, 250),
        [items]
    );

    const handleChangeSearchText = (value) => {
        setSearchText(value);
        delayedSearch(value);
    }

    return (
        <div className="mt-300">
            <Popover
                fullWidth
                active={popoverActive}
                activator={<>
                    <TextField
                        loading={searchLoading}
                        value={searchText}
                        placeholder="Search theme..."
                        onChange={handleChangeSearchText}
                        prefix={<Icon source={SearchIcon} tone="base" />}
                    />
                </>}
                autofocusTarget="first-node"
                onClose={togglePopoverActive}
            >
                <ActionList
                    actionRole="menuitem"
                    items={sections}
                />
            </Popover>
        </div>
    )
}

export default SearchThemes;