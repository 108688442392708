import { Button, Text, TextField } from "@shopify/polaris"
import { useEffect, useState } from "react"
import { ENDPOINT } from "../../../utils/functions";
import CodeMirror from "@uiw/react-codemirror";
import { liquid as liquidLang } from "@codemirror/lang-liquid"
import { color } from '@uiw/codemirror-extensions-color';

function CustomLiquid({ item }) {
    const [cssState, setCssStatus] = useState('')
    const [loading, setLoading] = useState(false);
    const [fetchContentLoading, setFetchContentLoading] = useState(false);
    const [fetchContentMessage, setFetchContentMessage] = useState('');

    const [themeId, setThemeId] = useState('');
    const [liquidCode, setLiquidCode] = useState({
        fileName: '',
        fileCode: ''
    })

    useEffect(() => {
        setThemeId(item.meta.theme);
    }, []);

    const handleSaveCss = async (shopUrl) => {
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/save-liquid`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl: item.shopUrl,
                themeId,
                liquidCode
            }),
        });
        const result = await response.json();
        setCssStatus(result?.message)
        setLoading(false);

        setTimeout(() => {
            setCssStatus('')
        }, 5000)
    }

    const handleChangeCss = (value) => {
        setLiquidCode(prev => ({ ...prev, fileCode: value }))
    }

    const handleFetchContentFile = async () => {
        setFetchContentLoading(true);
        setFetchContentMessage('');

        const response = await fetch(`${ENDPOINT}/api/get-liquid`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl: item.shopUrl,
                themeId,
                liquidCode
            }),
        });
        const result = await response.json();
        if (result.status) {
            setLiquidCode(prev => ({ ...prev, fileCode: result.data }))
        } else {
            setLiquidCode(prev => ({ ...prev, fileCode: '' }))
            setFetchContentMessage('Fetch liquid file failed or this file is not exists');
        }
        setFetchContentLoading(false);
    }

    return (
        <>
            <div className="css-records mb-400">
                <label>File name</label>
                <div className="mb-300 d-flex gap-300 mt-100">
                    <div className="flex-1 d-flex gap-100">
                        <div className="flex-1">
                            <TextField
                                placeholder="sections/smi-featured-collection-1-1.liquid"
                                value={liquidCode.fileName}
                                onChange={value => setLiquidCode(prev => ({ ...prev, fileName: value }))}
                                autoComplete="off"
                            />
                        </div>
                        <TextField
                            placeholder="Theme id"
                            value={themeId}
                            onChange={value => setThemeId(value)}
                            autoComplete="off"
                        />
                    </div>
                    <Button onClick={handleFetchContentFile} loading={fetchContentLoading}>Get file content</Button>
                </div>
                {
                    fetchContentMessage !== '' ? <div className="mb-300">
                        <Text tone="critical">{fetchContentMessage}</Text>
                    </div> : null
                }

                <label className="mb-100 d-block">File code</label>
                <CodeMirror
                    label="Custom Liquid"
                    value={liquidCode.fileCode}
                    height="300px"
                    options={{
                        foldGutter: true,
                        syntaxHighlighting: true,
                        theme: 'monokai',
                        keyMap: 'sublime',
                        mode: 'liquid',
                    }}
                    extensions={[liquidLang(), color]}
                    onChange={handleChangeCss}
                />
            </div>
            <div className="d-flex align-items-center gap-300">
                <Button variant="primary" loading={loading} onClick={() => handleSaveCss(item?.shopUrl)}>Save Liquid</Button>
                {cssState ? <Text>{cssState}</Text> : ''}
            </div>
        </>
    )
}

export default CustomLiquid