import { Button, Text } from "@shopify/polaris";
import { useState } from "react";
import { ENDPOINT } from "../../utils/functions";

function UpdateMerchantInfo() {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState('');

    const handleUpdate = async() => {
        setMessages('');
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/update-merchant-info`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            }
        });
        const result = await response.json();
        setMessages(result.message);
        setLoading(false);
    }

    return (
        <>
            <Text as="h2" variant="headingMd">Cập nhật thông tin của merchant</Text>
            <Text>Các khách hàng cũ không cập nhật được thông tin Shopify plan/name/email do trước đó chưa bắt hook shop_update</Text>
            <div className="mt-500"></div>
            <Button variant="primary" onClick={handleUpdate} loading={loading}>Cập nhật</Button>
            &nbsp; &nbsp;{messages !== '' ? messages : ''}
        </>
    )
}

export default UpdateMerchantInfo;