import { BlockStack, Box, Button, DatePicker, Icon, InlineGrid, InlineStack, OptionList, Popover, Scrollable, Select, TextField, useBreakpoints } from "@shopify/polaris";
import { useEffect, useRef, useState } from "react";
import { CalendarIcon, ArrowRightIcon } from '@shopify/polaris-icons';
import { format } from 'date-fns';

// This example is for guidance purposes. Copying it will come with caveats.
function DateRangePicker({ data, onChange }) {
    const { mdDown, lgUp } = useBreakpoints();
    const shouldShowMultiMonth = lgUp;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const endToday = new Date(new Date().setHours(23, 59, 59, 999));
    const yesterday = new Date(
        new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0)
    );
    const endYesterday = new Date(
        new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59, 999)
    );

    const firstDayLastYear = new Date(today.getFullYear() - 1, 0, 1);
    firstDayLastYear.setHours(0, 0, 0, 0);

    const lastDayLastYear = new Date(today.getFullYear() - 1, 11, 31);
    lastDayLastYear.setHours(23, 59, 59, 999);

    const ranges = [
        {
            title: "Custom",
            alias: "custom",
            period: {
                since: today,
                until: today,
            },
        },
        {
            title: "Today",
            alias: "today",
            period: {
                since: today,
                until: endToday,
            },
        },
        {
            title: "Yesterday",
            alias: "yesterday",
            period: {
                since: yesterday,
                until: endYesterday,
            },
        },
        {
            title: "Last 7 days",
            alias: "last7days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 6)).setHours(0, 0, 0, 0)
                ),
                until: endToday,
            },
        },
        {
            title: "Last 30 days",
            alias: "last30days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 29)).setHours(0, 0, 0, 0)
                ),
                until: endToday,
            },
        },
        {
            title: "Last 90 days",
            alias: "last90days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 89)).setHours(0, 0, 0, 0)
                ),
                until: endToday,
            },
        },
        {
            title: "Last 365 days",
            alias: "last365days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 364)).setHours(0, 0, 0, 0)
                ),
                until: endToday,
            },
        },
        {
            title: "Last month",
            alias: "lastmonth",
            period: {
                since: new Date(new Date(today.getFullYear(), today.getMonth() - 1, 1).setHours(0, 0, 0, 0)),
                until: new Date(new Date(today.getFullYear(), today.getMonth(), 0).setHours(23, 59, 59, 999)),
            },
        },
        {
            title: "Last year",
            alias: "lastyear",
            period: {
                since: firstDayLastYear,
                until: lastDayLastYear
            },
        },
    ];

    const [popoverActive, setPopoverActive] = useState(false);
    const [activeDateRange, setActiveDateRange] = useState(ranges[1]);
    const [inputValues, setInputValues] = useState({});
    const [{ month, year }, setDate] = useState({
        month: activeDateRange.period.since.getMonth(),
        year: activeDateRange.period.since.getFullYear(),
    });
    const datePickerRef = useRef(null);
    const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
    const [buttonLabel, setButtonLabel] = useState('Today');

    useEffect(() => {
        if (data?.title) {
            setActiveDateRange(data);
            apply(data, false);
        }
    }, [data]);

    function isDate(date) {
        return !isNaN(new Date(date).getDate());
    }

    function isValidYearMonthDayDateString(date) {
        return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
    }

    function isValidDate(date) {
        return date.length === 10 && isValidYearMonthDayDateString(date);
    }

    function parseYearMonthDayDateString(input) {
        // Date-only strings (e.g. "1970-01-01") are treated as UTC, not local time
        // when using new Date()
        // We need to split year, month, day to pass into new Date() separately
        // to get a localized Date
        const [year, month, day] = input.split("-");
        return new Date(Number(year), Number(month) - 1, Number(day));
    }

    function formatDateToYearMonthDayDateString(date) {
        const year = String(date.getFullYear());
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        if (month.length < 2) {
            month = String(month).padStart(2, "0");
        }
        if (day.length < 2) {
            day = String(day).padStart(2, "0");
        }
        return [year, month, day].join("-");
    }

    function formatDate(date) {
        return formatDateToYearMonthDayDateString(date);
    }

    function nodeContainsDescendant(rootNode, descendant) {
        if (rootNode === descendant) {
            return true;
        }
        let parent = descendant.parentNode;
        while (parent != null) {
            if (parent === rootNode) {
                return true;
            }
            parent = parent.parentNode;
        }
        return false;
    }

    function isNodeWithinPopover(node) {
        return datePickerRef?.current
            ? nodeContainsDescendant(datePickerRef.current, node)
            : false;
    }

    function handleStartInputValueChange(value) {
        setInputValues((prevState) => {
            return { ...prevState, since: value };
        });
        console.log("handleStartInputValueChange, validDate", value);
        if (isValidDate(value)) {
            const newSince = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newSince <= prevState.period.until
                        ? { since: newSince, until: prevState.period.until }
                        : { since: newSince, until: newSince };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }

    function handleEndInputValueChange(value) {
        setInputValues((prevState) => ({ ...prevState, until: value }));
        if (isValidDate(value)) {
            const newUntil = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newUntil >= prevState.period.since
                        ? { since: prevState.period.since, until: newUntil }
                        : { since: newUntil, until: newUntil };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }

    function handleInputBlur({ relatedTarget }) {
        const isRelatedTargetWithinPopover =
            relatedTarget != null && isNodeWithinPopover(relatedTarget);
        // If focus moves from the TextField to the Popover
        // we don't want to close the popover
        if (isRelatedTargetWithinPopover) {
            return;
        }
        setPopoverActive(false);
    }

    function handleMonthChange(month, year) {
        setDate({ month, year });
    }

    function handleCalendarChange({ start, end }) {
        const newDateRange = ranges.find((range) => {
            return (
                range.period.since.valueOf() === start.valueOf() &&
                range.period.until.valueOf() === end.valueOf()
            );
        }) || {
            alias: "custom",
            title: "Custom",
            period: {
                since: start,
                until: end,
            },
        };
        setActiveDateRange(newDateRange);
    }

    function apply(data = false, emit = true) {
        
        
        let _activeDateRange = activeDateRange;
        if(!!data) {
            _activeDateRange = data;
        }
        setPopoverActive(false);
        const _label = _activeDateRange.title === "Custom"
            ? _activeDateRange.period.since.toDateString() +
            " - " +
            _activeDateRange.period.until.toDateString()
            : _activeDateRange.title;

        setButtonLabel(_label);


        if (!!emit) {
            onChange(_activeDateRange);
        }
    }

    useEffect(() => {        
        if (activeDateRange) {
            setInputValues({
                since: formatDate(activeDateRange.period.since),
                until: formatDate(activeDateRange.period.until),
            });
            function monthDiff(referenceDate, newDate) {
                return (
                    newDate.month -
                    referenceDate.month +
                    12 * (referenceDate.year - newDate.year)
                );
            }
            const monthDifference = monthDiff(
                { year, month },
                {
                    year: activeDateRange.period.until.getFullYear(),
                    month: activeDateRange.period.until.getMonth(),
                }
            );
            if (monthDifference > 1 || monthDifference < 0) {
                setDate({
                    month: activeDateRange.period.until.getMonth(),
                    year: activeDateRange.period.until.getFullYear(),
                });
            }
        }
    }, [activeDateRange]);

    return (
        <Popover
            active={popoverActive}
            autofocusTarget="none"
            preferredAlignment="left"
            preferredPosition="below"
            fluidContent
            sectioned={false}
            fullHeight
            activator={
                <Button
                    size="slim"
                    icon={CalendarIcon}
                    onClick={() => setPopoverActive(!popoverActive)}
                >
                    {buttonLabel}
                </Button>
            }
            onClose={() => setPopoverActive(false)}
        >
            <Popover.Pane fixed>
                <InlineGrid
                    columns={{
                        xs: "1fr",
                        mdDown: "1fr",
                        md: "max-content max-content",
                    }}
                    gap={0}
                    ref={datePickerRef}
                >
                    <Box
                        maxWidth={mdDown ? "516px" : "212px"}
                        width={mdDown ? "100%" : "212px"}
                        padding={{ xs: 500, md: 0 }}
                        paddingBlockEnd={{ xs: 100, md: 0 }}
                    >
                        {mdDown ? (
                            <Select
                                label="dateRangeLabel"
                                labelHidden
                                onChange={(value) => {
                                    const result = ranges.find(
                                        ({ title, alias }) => title === value || alias === value
                                    );
                                    setActiveDateRange(result);
                                }}
                                value={activeDateRange?.title || activeDateRange?.alias || ""}
                                options={ranges.map(({ alias, title }) => title || alias)}
                            />
                        ) : (
                            <Scrollable style={{ height: "334px" }}>
                                <OptionList
                                    options={ranges.map((range) => ({
                                        value: range.alias,
                                        label: range.title,
                                    }))}
                                    selected={activeDateRange.alias}
                                    onChange={(value) => {
                                        setActiveDateRange(
                                            ranges.find((range) => range.alias === value[0])
                                        );
                                    }}
                                />
                            </Scrollable>
                        )}
                    </Box>
                    <Box padding={{ xs: 500 }} maxWidth={mdDown ? "320px" : "516px"}>
                        <BlockStack gap="400">
                            <InlineStack gap="200">
                                <div style={{ flexGrow: 1 }}>
                                    <TextField
                                        role="combobox"
                                        label={"Since"}
                                        labelHidden
                                        prefix={<Icon source={CalendarIcon} />}
                                        value={inputValues.since}
                                        onChange={handleStartInputValueChange}
                                        onBlur={handleInputBlur}
                                        autoComplete="off"
                                    />
                                </div>
                                <Icon source={ArrowRightIcon} />
                                <div style={{ flexGrow: 1 }}>
                                    <TextField
                                        role="combobox"
                                        label={"Until"}
                                        labelHidden
                                        prefix={<Icon source={CalendarIcon} />}
                                        value={inputValues.until}
                                        onChange={handleEndInputValueChange}
                                        onBlur={handleInputBlur}
                                        autoComplete="off"
                                    />
                                </div>
                            </InlineStack>
                            <div>
                                <DatePicker
                                    month={month}
                                    year={year}
                                    selected={{
                                        start: activeDateRange.period.since,
                                        end: activeDateRange.period.until,
                                    }}
                                    onMonthChange={handleMonthChange}
                                    onChange={handleCalendarChange}
                                    multiMonth={shouldShowMultiMonth}
                                    disableDatesAfter={new Date()}
                                    allowRange
                                />
                            </div>
                        </BlockStack>
                    </Box>
                </InlineGrid>
            </Popover.Pane>
            <Popover.Pane fixed>
                <Popover.Section>
                    <InlineStack align="end">
                        <Button primary onClick={() => {apply(false)}}>
                            Apply
                        </Button>
                    </InlineStack>
                </Popover.Section>
            </Popover.Pane>
        </Popover>
    )
}

export default DateRangePicker;