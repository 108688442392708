import { Card, Page, Spinner, Tabs, Text } from "@shopify/polaris"
import { useCallback, useEffect, useState } from "react";
import Sections from "./Sections";
import Templates from "./Templates";

function Pricing() {
    //const [searchParams, setSearchParams] = useSearchParams();
    //const shop = searchParams.get('shop_url')
    const shop = ''

    const tabs = [
        {
            id: 'sections',
            content: <Text as="h3" fontWeight="headingSm">Sections</Text>,
            panelID: 'sections',
        },
        {
            id: 'templates',
            content: <Text as="h3" fontWeight="headingSm">Templates</Text>,
            panelID: 'templates',
        }
    ];

    const [tabSelected, setTabSelected] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(shop && shop !== '') {
            setSearchText(shop)
            setTabSelected(1)
        }
    }, [shop])

    const handleTabChange = useCallback(
        (selectedTabIndex) => setTabSelected(selectedTabIndex),
        [],

    );

    const handleViewDetail = (value) => {
        setSearchText(value)
        setTabSelected(1)
    }

    const handleSetLoading = (value) => {
        setLoading(value)
    }

    return (
        <div className="admin-merchant-logs-page">
            {loading && <div className="_loading"><Spinner size="large" /></div>}
            <Page title="Merchant logs" >
                <Card>
                    <div className="">
                        <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange} fitted={true}>
                            <div className="p-300">
                                <Card>
                                    {tabSelected === 0 ? <Sections /> : null}
                                    {tabSelected === 1 ? <Templates /> : null}
                                </Card>
                            </div>
                        </Tabs>
                    </div>
                </Card>
            </Page>
        </div>
    )
}

export default Pricing