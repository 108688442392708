import { Button, Text } from "@shopify/polaris"
import { useEffect, useState } from "react"
import { ENDPOINT } from "../../../utils/functions";
import CodeMirror from "@uiw/react-codemirror";
import { javascript as jsLang } from "@codemirror/lang-javascript"
import { color } from '@uiw/codemirror-extensions-color';

function CustomJs({ item }) {
    const [cssCode, setCssCode] = useState('Loading data...')
    const [cssState, setCssStatus] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getCustomCss = async () => {
            setCssCode('Loading data...');
            setLoading(true);

            const response = await fetch(`${ENDPOINT}/api/get-js`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    shopUrl: item.shopUrl
                }),
            });

            const result = await response.json();
            setCssCode(result.data);
            setLoading(false);
        }

        getCustomCss();
    }, [])

    const handleSaveCss = async (shopUrl) => {
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/save-js`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                jsCode: cssCode
            }),
        });
        const result = await response.json();
        setCssStatus(result?.message)
        setLoading(false);

        setTimeout(() => {
            setCssStatus('')
        }, 5000)
    }

    const handleChangeCss = (value) => {
        setCssCode(value)
    }

    return (
        <>
            <div className="css-records mb-400">
                <CodeMirror
                    label="Custom JS"
                    value={cssCode}
                    height="300px"
                    options={{
                        foldGutter: true,
                        syntaxHighlighting: true,
                        theme: 'monokai',
                        keyMap: 'sublime',
                        mode: 'javascript',
                    }}
                    extensions={[jsLang(), color]}
                    onChange={handleChangeCss}
                />
            </div>
            <div className="d-flex align-items-center gap-300">
                <Button variant="primary" loading={loading} onClick={() => handleSaveCss(item?.shopUrl)}>Save JS</Button>
                {cssState ? <Text>{cssState}</Text> : ''}
            </div>
        </>
    )
}

export default CustomJs