import { Banner, Button, DataTable, Modal, Spinner, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { ENDPOINT } from "../../utils/functions";
import { format } from "date-fns";
import Pagination from "./Pagination";
import SyncStyles from "./sync/SyncStyles";

function Styles({ filters, username }) {
    const [rows, setRows] = useState([[<Banner>No data</Banner>]]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [detailRows, setDetailRows] = useState([]);
    const [modalTitle, setModalTitle] = useState('Detail');
    const [counter, setCounter] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1
    });
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
        const handleFilter = async () => {
            if (filters?.dateRangeFilter) {
                const filterObj = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...filters,
                        dataType: 'style'
                    }),
                };

                const responseCounter = await fetch(`${ENDPOINT}/api/get-counter-installations`, filterObj);
                const resultCounter = await responseCounter.json();
                setCounter(resultCounter.data);
            }
        }

        handleFilter();
    }, [filters])

    useEffect(() => {
        const handleFilter = async () => {
            setLoading(true);
            setRows([[
                <div className="d-flex align-items-center justify-center p-600"><Spinner /></div>
            ]]);

            const filterObj = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...filters,
                    dataType: 'style'
                }),
            };
            const response = await fetch(`${ENDPOINT}/api/get-top-installations`, filterObj);
            const result = await response.json();

            if (result.data.length) {
                setItems(result.data);
                const _rows = [];
                const startIndex = (page - 1) * 100;
                result.data.map((item, index) => {
                    _rows.push([
                        <b>{(startIndex + index + 1)}</b>,
                        <b>{item.name}</b>,
                        <b>{item.count}</b>,
                        <>
                            <Button onClick={async () => { handleViewDetail(item, filters); }}>Detail</Button>
                        </>,
                    ]);
                })
                setRows(_rows);
            } else {
                setItems([]);
                setRows([[
                    <Banner>No data</Banner>
                ]]);
            }
            setLoading(false);
        }

        handleFilter();

    }, [filters])

    const handleViewDetail = async (item, filters) => {
        setModalTitle(`Detail for "${item.name}"`);
        setModalActive(true);
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/get-detail-installations`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...filters,
                dataType: 'style',
                handle: item.handle
            }),
        });
        const result = await response.json();

        const _rows = [];
        result.data.map((itemData) => {
            _rows.push({
                ...itemData.items.meta,
                shopifyPlan: itemData.items.shopifyPlan,
                appPlan: itemData.items.appPlan,
                createdAt: format(itemData.items.createdAt, "dd-MM-yyyy HH:mm:ss", { timeZone: 'Asia/Bangkok' })
            });
        });
        setDetailRows(_rows);
        setLoading(false);
    }

    const [toggleSync, setToggleSync] = useState(false);
    const [loadingSync, setLoadingSync] = useState(false);

    const handleSyncToApp = () => {
        setLoadingSync(true);
        setToggleSync(prev => !prev);
        setLoadingSync(false);
    }

    const handleFetchDataToRightPanel = () => {
        return items;
    }

    return (
        <div className="d-flex gap-600">
            <div style={{ width: '700px' }}>
                <div className="d-flex gap-200">
                    <div className="flex-1">
                        <Banner hideIcon><Text variant="headingLg">{counter} {counter === 1 ? 'install' : 'installs'}</Text></Banner>
                    </div>
                    {
                        username === 'jreamoq' || username === 'super' ? <Button onClick={handleSyncToApp} loading={loadingSync}>{toggleSync ? 'Close sync panel' : 'Open sync panel'}</Button> : null
                    }

                </div>
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text'
                    ]}
                    headings={[
                        <b>#</b>,
                        <b>Name</b>,
                        <b>Number of installations</b>,
                        <b></b>
                    ]}
                    rows={rows}
                />
                <Pagination data={pagination} loading={loading} onChange={value => setPage(value)} />
            </div>

            <SyncStyles toggle={toggleSync} onFetch={handleFetchDataToRightPanel} />

            <Modal
                size="large"
                open={modalActive}
                onClose={() => {
                    setModalActive(false);
                }}
                title={modalTitle}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: () => {
                            setModalActive(false);
                        },
                    },
                ]}
            >
                <Modal.Section>
                    {
                        !!loading ? (
                            <div className="d-flex align-items-center justify-center" style={{ minHeight: '200px' }}>
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                <table className="detail-table">
                                    <tr>
                                        <th>Shop URL</th>
                                        <th>Theme ID</th>
                                        <th>App plan</th>
                                        <th>Shopify plan</th>
                                        <th>Created at</th>
                                    </tr>
                                    {
                                        detailRows.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <a target="_blank" href={`https://${item?.shopUrl}?preview_theme_id=${item?.themeId}`}>{item?.shopUrl ?? '--'}</a>
                                                    </td>
                                                    <td>{item?.themeId ?? '--'}</td>
                                                    <td>{item?.appPlan ?? '--'}</td>
                                                    <td>{item?.shopifyPlan ?? '--'}</td>
                                                    <td>{item?.createdAt ?? '--'}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        )
                    }
                </Modal.Section>
            </Modal>
        </div>
    )
}

export default Styles;