import { Banner, Button, DataTable, Icon, Modal, Spinner, Text } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { ENDPOINT } from "../../utils/functions";
import { format } from "date-fns";
import Pagination from "./Pagination";
import Search from "../merchant/Search";
import { ArrowLeftIcon } from '@shopify/polaris-icons';

function TopTheme({ filters, username }) {
    const [rows, setRows] = useState([[<Banner>No data</Banner>]]);
    const [loading, setLoading] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [detailRows, setDetailRows] = useState([]);
    const [modalTitle, setModalTitle] = useState('Detail');
    const [counter, setCounter] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1
    });
    const [page, setPage] = useState(1);
    const [paginationDetail, setPaginationDetail] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1
    });
    const [pageDetail, setPageDetail] = useState(1);
    const [currentItem, setCurrentItem] = useState(null);
    const [shopUrl, setShopUrl] = useState('');
    const [detailActive, setDetailActive] = useState(false);

    useEffect(() => {
        setPage(1);
        const handleFilter = async () => {
            const filterObj = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...filters,
                    page
                }),
            };

            try {
                const responseCounter = await fetch(`${ENDPOINT}/api/get-counter-top-theme-installations`, filterObj);
                const resultCounter = await responseCounter.json();
                setCounter(resultCounter.data);
            } catch (err) { }
        }

        handleFilter();
    }, [filters])

    useEffect(() => {
        const handleFilter = async () => {
            if (filters?.dateRangeFilter) {
                setLoading(true);
                setRows([[
                    <div className="d-flex align-items-center justify-center p-600"><Spinner /></div>
                ]]);

                const filterObj = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...filters,
                        page
                    }),
                };

                const response = await fetch(`${ENDPOINT}/api/get-top-theme-installations`, filterObj);
                const result = await response.json();

                if (result.data.length) {
                    const _rows = [];
                    const startIndex = (page - 1) * 100;
                    result.data.map((item, index) => {
                        _rows.push([
                            <b>{(startIndex + index + 1)}</b>,
                            <b>{item._id}</b>,
                            <b>{item.count}</b>,
                            <>
                                <Button onClick={async () => { handleViewDetail(item, filters); }}>Detail</Button>
                            </>,
                        ]);
                    })
                    setRows(_rows);
                } else {
                    setRows([[
                        <Banner>No data</Banner>
                    ]]);
                }
                setPagination(result.pagination);
                setLoading(false);
            }
        }

        handleFilter();

    }, [filters, page])

    const handleViewDetail = async (item, filters) => {
        setCurrentItem(item);
        setModalTitle(`Detail for "${item._id}"`);
        setModalActive(true);
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/get-detail-top-theme-installations`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...filters,
                handle: item._id,
                page: pageDetail
            }),
        });
        const result = await response.json();

        const _rows = [];
        result.data.map((itemData) => {
            _rows.push({
                shopUrl: itemData?._id?.shopUrl,
                themeId: itemData?._id?.themeId
            });
        });
        setPaginationDetail(result.pagination);
        setDetailRows(_rows);
        setLoading(false);
    }

    const handleChangePageDetail = async (value) => {
        setPageDetail(value);
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/get-detail-top-theme-installations`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...filters,
                handle: currentItem._id,
                page: value
            }),
        });
        const result = await response.json();

        const _rows = [];
        result.data.map((itemData) => {
            _rows.push({
                shopUrl: itemData?._id?.shopUrl,
                themeId: itemData?._id?.themeId
            });
        });
        setPaginationDetail(result.pagination);
        setDetailRows(_rows);
        setLoading(false);
    }

    const handleRedirectToDetail = (shop) => {
        setDetailActive(true);
        setShopUrl(shop)
    }

    return (
        <div>
            <div style={{ width: '700px' }}>
                <Banner hideIcon><Text variant="headingLg">{counter} {counter === 1 ? 'install' : 'installs'}</Text></Banner>
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text'
                    ]}
                    headings={[
                        <b>#</b>,
                        <b>Name</b>,
                        <b>Number of installations</b>,
                        <b></b>
                    ]}
                    rows={rows}
                />
                <Pagination data={pagination} loading={loading} onChange={value => setPage(value)} />
            </div>

            <Modal
                size="fullScreen"
                open={modalActive}
                onClose={() => {
                    setModalActive(false);
                    setDetailActive(false);
                    setShopUrl('');
                }}
                title={!!detailActive && shopUrl !== '' ? <div className="smind-app d-flex gap-200 align-items-center">
                    <Button onClick={() => {
                        setDetailActive(false);
                        setShopUrl('');
                    }}><div className="d-flex gap-100 align-items-center"><Icon source={ArrowLeftIcon} tone="base"/>Back</div></Button>
                    <span>View detail "{shopUrl}"</span>
                    </div> : modalTitle}
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: () => {
                            setModalActive(false);
                            setDetailActive(false);
                            setShopUrl('');
                        },
                    },
                ]}
            >
                <Modal.Section>
                    {
                        !!loading ? (
                            <div className="d-flex align-items-center justify-center" style={{ minHeight: '200px' }}>
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                <div style={{ display: `${!!detailActive && shopUrl !== '' ? 'none' : 'block'}` }}>
                                    <table className="detail-table">
                                        <tr>
                                            <th>Shop URL</th>
                                            <th>Theme ID</th>
                                            <th></th>
                                        </tr>
                                        {
                                            detailRows.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <a target="_blank" href={`https://${item?.shopUrl}?preview_theme_id=${item?.themeId}`}>{item?.shopUrl ?? '--'}</a>
                                                        </td>
                                                        <td>{item?.themeId ?? '--'}</td>
                                                        <td><Button onClick={() => handleRedirectToDetail(item?.shopUrl)}>Detail</Button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                    <Pagination data={paginationDetail} loading={loading} onChange={value => handleChangePageDetail(value)} />
                                </div>

                                {
                                    shopUrl !== '' && detailActive ?
                                        <div className="detail-for-shop" style={{minHeight: '100%'}}>
                                            <div className="smind-app admin-merchant-logs-page">
                                                <Search _s={shopUrl} username={username} />
                                            </div>
                                        </div> : null
                                }

                            </div>
                        )
                    }
                </Modal.Section>
            </Modal>
        </div>
    )
}

export default TopTheme;