import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash"
import { Badge, Button, ButtonGroup, Icon, Select, Spinner, Text, TextField } from "@shopify/polaris"
import Pagination from "../common/Pagination";
import { ENDPOINT, convertToAsiaTimeZone } from "../../utils/functions";

function All(props) {
    //State
    const [merchants, setMerchants] = useState([])
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({ total: 0 })
    const limit = 10
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('recent')
    const [subSort, setSubSort] = useState('all')
    const [filters, setFilters] = useState({ _s: '', sort: 'recent', status: 'all' })
    const [status, setStatus] = useState('all')
    const [loadingMerchant, setLoadingMerchant] = useState(false)
    const shopUrl = ''

    const subSortOptions = sort === 'shopify-plan' ? [
        {
            label: 'Shopify Basic',
            value: 'Basic Shopify'
        },
        {
            label: 'Shopify Only',
            value: 'Shopify'
        },
        {
            label: 'Shopify Advanced',
            value: 'Advanced Shopify'
        },
        {
            label: 'Shopify Plus',
            value: 'Shopify Plus'
        },
        {
            label: 'Shopify Trial',
            value: 'trial'
        },
        {
            label: 'Shopify Staff',
            value: 'staff'
        },
        {
            label: 'Pause and Build',
            value: 'Pause and Build'
        },
        {
            label: 'Developer Preview',
            value: 'Developer Preview'
        },
        {
            label: 'Development',
            value: 'Development'
        }

    ] : [
        {
            label: 'Free',
            value: '[MONTHLY] Free'
        },
        {
            label: '[MONTHLY] Basic',
            value: '[MONTHLY] Basic'
        },
        {
            label: '[ANNUALLY] Basic',
            value: '[ANNUALLY] Basic'
        },
        {
            label: '[MONTHLY] Pro',
            value: '[MONTHLY] Pro'
        },
        {
            label: '[ANNUALLY] Pro',
            value: '[ANNUALLY] Pro'
        }
    ]

    const delayedSearch = useCallback(
        debounce((query) => {
            setFilters(prev => ({ ...prev, _s: query, page: 1 }))
        }, 250),
        []
    );

    useEffect(() => {
        const getMerchants = async () => {
            try {
                //props.onLoading(true)
                setLoadingMerchant(true)
                const response = await fetch(`${ENDPOINT}/api/get-merchants`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(filters),
                });
                const result = await response.json();
                if (result.status) {
                    setMerchants(result.nodes)
                    setPagination(result.pagination)
                }
            } catch (error) {
                console.error("Error:", error);
            }
            //props.onLoading(false)
            setLoadingMerchant(false)
        }

        getMerchants()
    }, [filters])

    useEffect(() => {
        setFilters(prev => ({ ...prev, page: page }))
    }, [page])

    const handleChangeSearchText = (value) => {
        setSearchText(value);
        delayedSearch(value);
    }

    const handleChangeSort = (value) => {
        setPage(1)
        setSort(value)
        if (value === 'shopify-plan') {
            handleChangeSubSort('Basic Shopify')
        }
        if (value === 'app-plan') {
            handleChangeSubSort('[MONTHLY] Free')
        }
        setFilters(prev => ({ ...prev, sort: value }))
    }

    const handleChangeStatus = (value) => {
        setPage(1)
        setStatus(value)
        setFilters(prev => ({ ...prev, status: value }))
    }

    const handleChangeSubSort = (value) => {
        setPage(1)
        setSubSort(value)
        setFilters(prev => ({ ...prev, subSort: value }))
    }

    const handleViewDetail = (event, shopUrl) => {
        event.preventDefault();
        props.onViewDetail(shopUrl)
    }

    return (
        <div className="all-merchants">
            <div className="d-flex gap-200 ">
                <div className="flex-1">
                    <TextField
                        placeholder="Enter your store info: Name, url, email"
                        type="text"
                        value={searchText}
                        onChange={handleChangeSearchText}
                    />

                </div>
                <div className="sort-group d-flex gap-200">
                    <Select labelInline label="Filter: " options={[
                        {
                            label: 'Recent',
                            value: 'rencent'
                        },
                        {
                            label: 'Shopify plan',
                            value: 'shopify-plan'
                        },
                        {
                            label: 'App plan',
                            value: 'app-plan'
                        }
                    ]}
                        value={sort}
                        onChange={handleChangeSort}
                    >
                    </Select>
                    {
                        sort === 'shopify-plan' || sort === 'app-plan' ? (
                            <Select options={subSortOptions} value={subSort} onChange={handleChangeSubSort}></Select>
                        ) : null
                    }
                    <Select labelInline label="Status: " options={[
                        {
                            label: 'All',
                            value: 'all'
                        },
                        {
                            label: 'Active',
                            value: 'active'
                        },
                        {
                            label: 'Uninstalled',
                            value: 'uninstalled'
                        }
                    ]}
                        value={status}
                        onChange={handleChangeStatus}
                    ></Select>
                </div>

            </div>

            {
                !merchants.length ? (
                    <div className="mt-300 d-flex gap-100 p-relative align-items-center">
                        <Text as="h3" variant="headingMd">No merchants</Text>
                        {!!loadingMerchant && <div className="loading"><Spinner /></div>}
                    </div>
                ) : (
                    <>
                        <div className="mt-300 mb-300 gap-100 d-flex p-relative align-items-center">
                            <Text as="h3" variant="headingMd">{pagination.total} merchants</Text>
                            {!!loadingMerchant && <div className="loading"><Spinner /></div>}
                        </div>
                        <table className="mt-300">
                            <thead>
                                <tr>
                                    <th>Information</th>
                                    <th>Status</th>
                                    <th>Counter</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    merchants.map((_item, idx) => {
                                        let item = _item
                                        let itemTemp = _item
                                        if (!!item.meta.isUninstalled && item?.oldData) {
                                            item = { ...item, ...item?.oldData }
                                        }

                                        let sectionData = item?.myLibrary?.sections || [];
                                        sectionData = Array.from(
                                            new Map(
                                                sectionData.map(item => [
                                                    `${item.name}-${item.theme}-${item.resource}`,
                                                    item
                                                ])
                                            ).values()
                                        );

                                        return (
                                            <tr key={`merchant-${idx}`}>
                                                <td>
                                                    <div className="">
                                                        <Text as="h3" fontWeight="headingMd">{item?.shopName}</Text>
                                                    </div>
                                                    <p className="mt-100">{item?.email}</p>
                                                    <p className="mt-100"><a href={`https://${item?.shopUrl}`} target="_blank">{item?.shopUrl}</a></p>
                                                    <p className="mt-100">{item?.meta.shopifyPlan}</p>
                                                    <p className="mt-100">{item?.plan.name}</p>
                                                    {
                                                        !!itemTemp.meta.isUninstalled ? (
                                                            <>
                                                                <p className="mt-100">Installed at: {convertToAsiaTimeZone(item.createdAt)}</p>
                                                                <p className="mt-100">Uninstalled at: {convertToAsiaTimeZone(item.updatedAt)}</p>
                                                            </>
                                                        ) : (
                                                            <p className="mt-100">Installed at: {convertToAsiaTimeZone(item.createdAt)}</p>
                                                        )
                                                    }
                                                    {
                                                        itemTemp.meta.isDeletedData ? (
                                                            <p className="mt-400">
                                                                <Badge tone="critical" hideIcon>
                                                                    <Text alignment="justify" as="p" fontWeight="bold">IS DELETED APP DATA</Text>
                                                                </Badge>
                                                            </p>
                                                        ) : null
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !!itemTemp.meta.isUninstalled ? (
                                                            <Badge tone="critical" hideIcon>
                                                                <Text alignment="justify" as="p" fontWeight="bold">UNINSTALLED</Text>
                                                            </Badge>
                                                        ) : (
                                                            <Badge tone="success" hideIcon>
                                                                <Text alignment="justify" as="p" fontWeight="bold">ACTIVE</Text>
                                                            </Badge>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <p className="mt-100">Sections: <b>{sectionData.length}</b></p>
                                                    <p className="mt-100">Templates: <b>{item.myLibrary.templates.length}</b></p>
                                                    <p className="mt-100">Themes: <b>{item.myLibrary.themes.length}</b></p>
                                                    <p className="mt-100">Styles: <b>{item.myLibrary.globalStyles.length}</b></p>
                                                    <p className="mt-100">Page builder: <b>{item.counterTemplate}</b></p>
                                                    <p className="mt-100">Theme builder: <b>{item.counterTheme}</b></p>
                                                </td>
                                                <td>
                                                    <a className="cursor-pointer" onClick={(event) => handleViewDetail(event, item?.shopUrl)}>Detail</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <Pagination
                            total={pagination.total}
                            limit={limit}
                            page={page}
                            onChange={page => { setPage(page) }}
                        /></>
                )
            }
        </div>
    )
}

export default All